import { useEffect, useState } from 'react';
import { useToast } from '@buydepa-public/overlays';
import {
  ArrowDownTrayIcon,
  TrashIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';
import type { ReactNode } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { useCustomMutation } from '@buydepa-public/graphql';
import ToastContent from '../ToastContent/ToastContent';
import Pdf from '../../icons/IconPdf';
import Card2 from '../Card2/Card2';
import Dropdown2 from '../Dropdown2/Dropdown2';

interface CardFileProps {
  key?: string | number;
  className?: string;
  id?: string | number;
  name: string;
  createdDate?: string;
  clarification?: string;
  subIcon?: ReactNode;
  actions?: ReactNode;
  fileUrl?: string;
  handleDelete?: (id: string) => void;
  loading?: boolean;
  handleDownload?: () => void;
  handlePreview?: () => void;
  handleOpenFilePreviewModal?: () => void;
  isInline?: boolean;
}

const DOWNLOAD_ACTION = 'Descargar';
const DELETE_ACTION = 'Eliminar';
const PREVIEW_ACTION = 'Ver';

export const formatDate = (date: string, minutes: boolean) => {
  const today = new Date(date);
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = String(today.getFullYear()).slice(-2);
  if (minutes) {
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const hours = String(today.getHours()).padStart(2, '0');
    return yyyy + '/' + mm + '/' + dd + ' ' + hours + ':' + minutes;
  }
  return yyyy + '/' + mm + '/' + dd;
};

export function CardFile({
  className = '',
  name,
  id,
  subIcon,
  actions,
  createdDate,
  clarification,
  handlePreview,
  handleDelete,
  handleDownload,
  loading = false,
  handleOpenFilePreviewModal,
  isInline,
}: CardFileProps) {
  const [dateFormated, setDateFormated] = useState('');
  const { open } = useToast();
  const [loadingState, setLoadingState] = useState(loading);
  const [downloadDoc, downloadDocLoading] = useCustomMutation({
    url: '/credits/api/get-document',
    onError: () => {
      open({
        type: 'error',
        content: (
          <ToastContent
            title="Error"
            subtitle="Ha ocurrido un error al descargar el documento"
          />
        ),
      });
    },
    onSuccess: (data) => {
      if (data) {
        const url = data.requestDocument?.data?.attributes?.fileUrl;
        if (url) {
          const downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.download = name;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
      open({
        type: 'success',
        content: (
          <ToastContent
            title="Operación exitosa"
            subtitle="El documento se descargó exitosamente"
          />
        ),
      });
    },
  });

  const menuOptions = [
    ...(handlePreview
      ? [
          {
            label: PREVIEW_ACTION,
            icon: <EyeIcon width={20} height={20} />,
          },
        ]
      : []),
    ...(handleDownload
      ? [
          {
            label: DOWNLOAD_ACTION,
            icon: <ArrowDownTrayIcon width={20} height={20} />,
          },
        ]
      : []),
    ...(handleDelete
      ? [
          {
            label: DELETE_ACTION,
            icon: <TrashIcon width={20} height={20} />,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (createdDate) {
      setDateFormated(formatDate(createdDate, false));
    }
  }, [createdDate]);

  const handleOptions = async ({ label }: { label: string }) => {
    if (label === DOWNLOAD_ACTION) {
      if (handleDownload) {
        setLoadingState(true);
        await handleDownload();
        setLoadingState(false);
      } else {
        downloadDoc({ id });
      }
    }
    if (label === PREVIEW_ACTION) {
      handlePreview && handlePreview();
      handleOpenFilePreviewModal && handleOpenFilePreviewModal();
    }
    if (label === DELETE_ACTION) {
      handleDelete && handleDelete(id as string);
    }
  };

  return isInline ? (
    <Card2
      variant="file"
      className={className}
      loading={downloadDocLoading || loadingState}
      childrenPadding="p-2"
    >
      <div className="flex w-full justify-between items-center py-2 px-4">
        <div>
          <Pdf color="#060C41" width={30} />
        </div>
        <div>
          <p className="font-semibold text-light-gray-600">{name}</p>
        </div>
        <div>
          {clarification && <p className="font-medium">{clarification}</p>}
        </div>
        <div>
          {dateFormated && <p className="font-light">Subido: {dateFormated}</p>}
        </div>
        <div>{actions}</div>
        <div>
          <Dropdown2
            options={menuOptions}
            selected={null}
            setSelected={handleOptions}
            color="white"
            customIcon={<EllipsisVerticalIcon width={24} height={24} />}
          />
        </div>
      </div>
    </Card2>
  ) : (
    <Card2
      className={`${className} relative min-w-[175px] max-w-[175px] min-h-[200px]`}
      variant="file"
      loading={downloadDocLoading || loadingState}
      childrenPadding="p-2"
    >
      <div className="relative flex justify-end">
        <div className="mr-7 mt-4">
          <Pdf color="#060C41" width={50} />
        </div>
        <div>
          <Dropdown2
            options={menuOptions}
            selected={null}
            setSelected={handleOptions}
            color="primary"
            width={16}
            height={16}
          />
        </div>
        {subIcon}
      </div>

      <div className="text-center text-light-gray-600 text-sm mt-4">
        <div className="font-semibold mb-1">{name}</div>
        {clarification && <div className="mb-3">{clarification}</div>}
        {dateFormated && <div>Subido: {dateFormated}</div>}
      </div>
    </Card2>
  );
}

export default CardFile;
