const RoundChileanFLag = ({ ...props }) => (
  <svg
    className="w-[15px] h-[15px] md:w-[31px] md:h-[31px]"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3190_4504)">
      <mask
        id="mask0_3190_4504"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="31"
      >
        <path d="M0 0.988281H31.0015V30.9883H0V0.988281Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3190_4504)">
        <path
          fill-rule="evenodd"
          clipRule="evenodd"
          d="M13 0.988281H31V18.7156H13V0.988281Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clipRule="evenodd"
          d="M0 0.988281H15V18.7156H0V0.988281Z"
          fill="#0039A6"
        />
        <path
          fill-rule="evenodd"
          clipRule="evenodd"
          d="M11.2438 15.9769L10.0062 14.7113L8.77187 15.9897L9.23125 13.9102L8 12.6275L9.52187 12.6062L9.99375 10.5352L10.4781 12.6019L12 12.6062L10.775 13.9016L11.2438 15.9769Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clipRule="evenodd"
          d="M0 18.7148H31V30.9876H0V18.7148Z"
          fill="#D52B1E"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3190_4504">
        <rect y="0.988281" width="31" height="30" rx="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default RoundChileanFLag;
