const RoundArgentinianFLag = () => (
  <svg
    className="w-[15px] h-[15px] md:w-[31px] md:h-[31px]"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3190_4513)">
      <mask
        id="mask0_3190_4513"
        maskUnits="userSpaceOnUse"
        x="-5"
        y="0"
        width="41"
        height="30"
      >
        <path d="M-5 0H35.002V29.6774H-5V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3190_4513)">
        <path
          fill-rule="evenodd"
          clipRule="evenodd"
          d="M11.7742 0H35V16.7742H11.7742V0Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clipRule="evenodd"
          d="M-5 0H14.3548V16.7742H-5V0Z"
          fill="#0039A6"
        />
        <path
          fill-rule="evenodd"
          clipRule="evenodd"
          d="M9.50824 14.1804L7.91146 12.9829L6.31872 14.1925L6.91146 12.2248L5.32275 11.0111L7.28646 10.9909L7.89533 9.03125L8.52033 10.9869L10.484 10.9909L8.9034 12.2167L9.50824 14.1804Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clipRule="evenodd"
          d="M-5 16.7734H35V28.3863H-5V16.7734Z"
          fill="#D52B1E"
        />
      </g>
      <g clipPath="url(#clip1_3190_4513)">
        <path d="M-5 0H35V32H-5V0Z" fill="#74ACDF" />
        <path d="M-5 9.89062H35V19.7831H-5V9.89062Z" fill="white" />
        <path
          d="M14.8081 14.916L16.5181 18.596C16.5181 18.596 16.5481 18.6673 16.5961 18.6495C16.6441 18.6257 16.6141 18.5604 16.6141 18.5604L15.1921 14.7617M15.1501 16.1922C15.1261 16.7501 15.4741 17.0588 15.4321 17.5573C15.3841 18.0618 15.6601 18.3408 15.7321 18.5367C15.7921 18.7326 15.6541 18.8453 15.7141 18.875C15.7741 18.9047 15.8941 18.7504 15.8581 18.4714C15.8161 18.1984 15.6061 18.1153 15.6541 17.5039C15.7021 16.8926 15.4021 16.7501 15.4741 16.1981"
          fill="#F6B40E"
        />
        <path
          d="M14.8081 14.916L16.5181 18.596C16.5181 18.596 16.5481 18.6673 16.5961 18.6495C16.6441 18.6257 16.6141 18.5604 16.6141 18.5604L15.1921 14.7617M15.1501 16.1922C15.1261 16.7501 15.4741 17.0588 15.4321 17.5573C15.3841 18.0618 15.6601 18.3408 15.7321 18.5367C15.7921 18.7326 15.6541 18.8453 15.7141 18.875C15.7741 18.9047 15.8941 18.7504 15.8581 18.4714C15.8161 18.1984 15.6061 18.1153 15.6541 17.5039C15.7021 16.8926 15.4021 16.7501 15.4741 16.1981"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M14.7928 14.8379L14.9491 18.8851C14.9491 18.8851 14.9493 18.9623 15.0005 18.964C15.054 18.9602 15.0516 18.8886 15.0516 18.8886L15.2073 14.8407M14.6151 16.1463C14.3771 16.6527 14.5793 17.0696 14.3476 17.5143C14.1081 17.9623 14.2551 18.3245 14.2459 18.5327C14.2255 18.7364 14.0544 18.7883 14.0984 18.8385C14.1423 18.8886 14.3129 18.7915 14.3875 18.5201C14.4544 18.252 14.2925 18.0957 14.5733 17.549C14.8542 17.0024 14.6321 16.7572 14.9122 16.2745"
          fill="#F6B40E"
        />
        <path
          d="M14.7928 14.8379L14.9491 18.8851C14.9491 18.8851 14.9493 18.9623 15.0005 18.964C15.054 18.9602 15.0516 18.8886 15.0516 18.8886L15.2073 14.8407M14.6151 16.1463C14.3771 16.6527 14.5793 17.0696 14.3476 17.5143C14.1081 17.9623 14.2551 18.3245 14.2459 18.5327C14.2255 18.7364 14.0544 18.7883 14.0984 18.8385C14.1423 18.8886 14.3129 18.7915 14.3875 18.5201C14.4544 18.252 14.2925 18.0957 14.5733 17.549C14.8542 17.0024 14.6321 16.7572 14.9122 16.2745"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M14.8091 14.7576L13.3878 18.5559C13.3878 18.5559 13.3581 18.6272 13.4048 18.6482C13.4557 18.665 13.4812 18.5978 13.4812 18.5978L15.191 14.917M14.1388 15.8991C13.723 16.2769 13.7485 16.7386 13.3624 17.0617C12.9678 17.3849 12.9636 17.7752 12.8745 17.9641C12.7769 18.1446 12.5987 18.1278 12.6199 18.1907C12.6411 18.2537 12.8363 18.2285 13.0102 18.0061C13.1757 17.7836 13.0866 17.578 13.5575 17.1792C14.0285 16.7805 13.9182 16.4699 14.3636 16.13"
          fill="#F6B40E"
        />
        <path
          d="M14.8091 14.7576L13.3878 18.5559C13.3878 18.5559 13.3581 18.6272 13.4048 18.6482C13.4557 18.665 13.4812 18.5978 13.4812 18.5978L15.191 14.917M14.1388 15.8991C13.723 16.2769 13.7485 16.7386 13.3624 17.0617C12.9678 17.3849 12.9636 17.7752 12.8745 17.9641C12.7769 18.1446 12.5987 18.1278 12.6199 18.1907C12.6411 18.2537 12.8363 18.2285 13.0102 18.0061C13.1757 17.7836 13.0866 17.578 13.5575 17.1792C14.0285 16.7805 13.9182 16.4699 14.3636 16.13"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M14.8547 14.6919L12.0722 17.663C12.0722 17.663 12.0172 17.7177 12.0522 17.7547C12.0927 17.7895 12.1422 17.7371 12.1422 17.7371L15.1457 14.9838M13.7937 15.4928C13.2635 15.6844 13.1084 16.1205 12.6267 16.273C12.1371 16.4222 11.9822 16.7812 11.8269 16.9219C11.6669 17.0517 11.5088 16.9687 11.504 17.0349C11.4992 17.1011 11.6893 17.1517 11.936 17.0121C12.175 16.8692 12.1722 16.6455 12.7615 16.4554C13.3509 16.2653 13.3691 15.9366 13.9122 15.7912"
          fill="#F6B40E"
        />
        <path
          d="M14.8547 14.6919L12.0722 17.663C12.0722 17.663 12.0172 17.7177 12.0522 17.7547C12.0927 17.7895 12.1422 17.7371 12.1422 17.7371L15.1457 14.9838M13.7937 15.4928C13.2635 15.6844 13.1084 16.1205 12.6267 16.273C12.1371 16.4222 11.9822 16.7812 11.8269 16.9219C11.6669 17.0517 11.5088 16.9687 11.504 17.0349C11.4992 17.1011 11.6893 17.1517 11.936 17.0121C12.175 16.8692 12.1722 16.6455 12.7615 16.4554C13.3509 16.2653 13.3691 15.9366 13.9122 15.7912"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M15.2583 16.2847C15.2883 16.8188 15.5943 17.0563 15.5343 17.5489C15.6663 17.1631 15.3483 16.8604 15.3663 16.2906M14.9043 14.8779L16.0743 17.4065L15.0963 14.8008"
          fill="#85340A"
        />
        <path
          d="M14.6783 16.2745C14.4994 16.7793 14.6903 17.1145 14.4442 17.547C14.7154 17.2405 14.5387 16.8405 14.7758 16.3208M14.8954 14.8408L14.9982 17.6198L15.1027 14.8422"
          fill="#85340A"
        />
        <path
          d="M14.1472 16.043C13.7866 16.4417 13.8332 16.8236 13.4387 17.13C13.8078 16.9495 13.7993 16.513 14.2193 16.1227M14.9024 14.8007L13.9223 17.407L15.0933 14.8804"
          fill="#85340A"
        />
        <path
          d="M13.7462 15.6258C13.2588 15.8576 13.1542 16.2281 12.6711 16.3618C13.082 16.3348 13.243 15.9283 13.782 15.7267M14.9245 14.7639L13.0108 16.8008L15.0701 14.9099"
          fill="#85340A"
        />
        <path
          d="M14.9216 14.6484L11.2016 16.3401C11.2016 16.3401 11.1296 16.3697 11.1476 16.4172C11.1716 16.4647 11.2376 16.435 11.2376 16.435L15.0776 15.0283M13.6316 14.9868C13.0676 14.963 12.7556 15.3073 12.2516 15.2657C11.7416 15.2182 11.4596 15.4913 11.2616 15.5625C11.0636 15.6219 10.9496 15.4853 10.9196 15.5447C10.8896 15.6041 11.0456 15.7228 11.3276 15.6871C11.6036 15.6456 11.6876 15.4379 12.3056 15.4853C12.9236 15.5328 13.0676 15.2361 13.6256 15.3073"
          fill="#F6B40E"
        />
        <path
          d="M14.9216 14.6484L11.2016 16.3401C11.2016 16.3401 11.1296 16.3697 11.1476 16.4172C11.1716 16.4647 11.2376 16.435 11.2376 16.435L15.0776 15.0283M13.6316 14.9868C13.0676 14.963 12.7556 15.3073 12.2516 15.2657C11.7416 15.2182 11.4596 15.4913 11.2616 15.5625C11.0636 15.6219 10.9496 15.4853 10.9196 15.5447C10.8896 15.6041 11.0456 15.7228 11.3276 15.6871C11.6036 15.6456 11.6876 15.4379 12.3056 15.4853C12.9236 15.5328 13.0676 15.2361 13.6256 15.3073"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M15.0014 14.6363L10.9102 14.7908C10.9102 14.7908 10.8322 14.791 10.8304 14.8417C10.8342 14.8946 10.9067 14.8922 10.9067 14.8922L14.9986 15.0463M13.6787 14.4605C13.1668 14.225 12.7454 14.425 12.2958 14.1958C11.843 13.9588 11.4769 14.1043 11.2664 14.0952C11.0605 14.0751 11.008 13.9058 10.9573 13.9493C10.9066 13.9927 11.0048 14.1615 11.2791 14.2353C11.5502 14.3014 11.7082 14.1413 12.2608 14.4191C12.8134 14.6969 13.0612 14.4773 13.5492 14.7543"
          fill="#F6B40E"
        />
        <path
          d="M15.0014 14.6363L10.9102 14.7908C10.9102 14.7908 10.8322 14.791 10.8304 14.8417C10.8342 14.8946 10.9067 14.8922 10.9067 14.8922L14.9986 15.0463M13.6787 14.4605C13.1668 14.225 12.7454 14.425 12.2958 14.1958C11.843 13.9588 11.4769 14.1043 11.2664 14.0952C11.0605 14.0751 11.008 13.9058 10.9573 13.9493C10.9066 13.9927 11.0048 14.1615 11.2791 14.2353C11.5502 14.3014 11.7082 14.1413 12.2608 14.4191C12.8134 14.6969 13.0612 14.4773 13.5492 14.7543"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M15.0806 14.6526L11.241 13.2466C11.241 13.2466 11.1689 13.2172 11.1477 13.2634C11.1307 13.3137 11.1986 13.3389 11.1986 13.3389L14.9194 15.0303M13.9266 13.9895C13.5448 13.5782 13.0781 13.6033 12.7514 13.2214C12.4247 12.8311 12.0302 12.8269 11.8392 12.7388C11.6568 12.6422 11.6738 12.4659 11.6101 12.4869C11.5465 12.5079 11.5719 12.701 11.7968 12.8731C12.0217 13.0367 12.2296 12.9486 12.6326 13.4145C13.0357 13.8803 13.3496 13.7712 13.6933 14.2119"
          fill="#F6B40E"
        />
        <path
          d="M15.0806 14.6526L11.241 13.2466C11.241 13.2466 11.1689 13.2172 11.1477 13.2634C11.1307 13.3137 11.1986 13.3389 11.1986 13.3389L14.9194 15.0303M13.9266 13.9895C13.5448 13.5782 13.0781 13.6033 12.7514 13.2214C12.4247 12.8311 12.0302 12.8269 11.8392 12.7388C11.6568 12.6422 11.6738 12.4659 11.6101 12.4869C11.5465 12.5079 11.5719 12.701 11.7968 12.8731C12.0217 13.0367 12.2296 12.9486 12.6326 13.4145C13.0357 13.8803 13.3496 13.7712 13.6933 14.2119"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M15.1473 14.6973L12.1439 11.9447C12.1439 11.9447 12.0886 11.8903 12.0512 11.9249C12.016 11.965 12.069 12.014 12.069 12.014L14.8523 14.9852M14.3377 13.6478C14.144 13.1232 13.7031 12.9698 13.5491 12.4933C13.3982 12.009 13.0353 11.8557 12.893 11.702C12.7618 11.5438 12.8457 11.3873 12.7788 11.3826C12.7119 11.3779 12.6607 11.5659 12.8019 11.81C12.9463 12.0464 13.1725 12.0437 13.3646 12.6267C13.5568 13.2096 13.8891 13.2277 14.0361 13.7649"
          fill="#F6B40E"
        />
        <path
          d="M15.1473 14.6973L12.1439 11.9447C12.1439 11.9447 12.0886 11.8903 12.0512 11.9249C12.016 11.965 12.069 12.014 12.069 12.014L14.8523 14.9852M14.3377 13.6478C14.144 13.1232 13.7031 12.9698 13.5491 12.4933C13.3982 12.009 13.0353 11.8557 12.893 11.702C12.7618 11.5438 12.8457 11.3873 12.7788 11.3826C12.7119 11.3779 12.6607 11.5659 12.8019 11.81C12.9463 12.0464 13.1725 12.0437 13.3646 12.6267C13.5568 13.2096 13.8891 13.2277 14.0361 13.7649"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M13.5356 15.0963C12.9956 15.126 12.7556 15.4287 12.2576 15.3693C12.6476 15.4999 12.9536 15.1853 13.5296 15.2031M14.9576 14.7461L12.4016 15.9035L15.0356 14.936"
          fill="#85340A"
        />
        <path
          d="M13.5485 14.5213C13.0382 14.3443 12.6993 14.5331 12.2622 14.2897C12.572 14.558 12.9764 14.3832 13.5017 14.6177M14.9978 14.7361L12.1886 14.8378L14.9963 14.9411"
          fill="#85340A"
        />
        <path
          d="M13.7823 13.9952C13.3792 13.6385 12.9932 13.6847 12.6834 13.2943C12.8659 13.6595 13.3071 13.6511 13.7017 14.0666M15.0381 14.7423L12.4034 13.7728L14.9575 14.9312"
          fill="#85340A"
        />
        <path
          d="M14.201 13.599C13.9666 13.1169 13.5921 13.0134 13.4569 12.5355C13.4842 12.9419 13.8951 13.1012 14.0989 13.6344M15.0722 14.7646L13.0131 12.8715L14.9247 14.9086"
          fill="#85340A"
        />
        <path
          d="M15.1919 14.7597L13.4819 11.0797C13.4819 11.0797 13.4519 11.0085 13.4039 11.0263C13.3559 11.0501 13.3859 11.1154 13.3859 11.1154L14.8079 14.9141M14.8499 13.4836C14.8739 12.9257 14.5259 12.617 14.5679 12.1185C14.6159 11.6139 14.3399 11.335 14.2679 11.1391C14.2079 10.9432 14.3459 10.8305 14.2859 10.8008C14.2259 10.7711 14.1059 10.9254 14.1419 11.2044C14.1839 11.4774 14.3939 11.5605 14.3459 12.1719C14.2979 12.7832 14.5979 12.9257 14.5259 13.4777"
          fill="#F6B40E"
        />
        <path
          d="M15.1919 14.7597L13.4819 11.0797C13.4819 11.0797 13.4519 11.0085 13.4039 11.0263C13.3559 11.0501 13.3859 11.1154 13.3859 11.1154L14.8079 14.9141M14.8499 13.4836C14.8739 12.9257 14.5259 12.617 14.5679 12.1185C14.6159 11.6139 14.3399 11.335 14.2679 11.1391C14.2079 10.9432 14.3459 10.8304 14.2859 10.8008C14.2259 10.7711 14.1059 10.9254 14.1419 11.2044C14.1839 11.4774 14.3939 11.5605 14.3459 12.1719C14.2979 12.7832 14.5979 12.9257 14.5259 13.4777"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M15.2072 14.8418L15.0509 10.7946C15.0509 10.7946 15.0507 10.7174 14.9995 10.7157C14.946 10.7195 14.9484 10.7911 14.9484 10.7911L14.7927 14.839M15.3849 13.5333C15.6229 13.027 15.4207 12.6101 15.6524 12.1653C15.8919 11.7174 15.7449 11.3552 15.7541 11.147C15.7745 10.9433 15.9456 10.8913 15.9016 10.8412C15.8577 10.7911 15.6871 10.8882 15.6125 11.1596C15.5456 11.4277 15.7075 11.584 15.4267 12.1306C15.1458 12.6773 15.3679 12.9225 15.0878 13.4052"
          fill="#F6B40E"
        />
        <path
          d="M15.2072 14.8418L15.0509 10.7946C15.0509 10.7946 15.0507 10.7174 14.9995 10.7157C14.946 10.7195 14.9484 10.7911 14.9484 10.7911L14.7927 14.839M15.3849 13.5333C15.6229 13.027 15.4207 12.6101 15.6524 12.1653C15.8919 11.7174 15.7449 11.3552 15.7541 11.147C15.7745 10.9433 15.9456 10.8913 15.9016 10.8412C15.8577 10.7911 15.6871 10.8882 15.6125 11.1596C15.5456 11.4277 15.7075 11.584 15.4267 12.1306C15.1458 12.6773 15.3679 12.9225 15.0878 13.4052"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M15.1909 14.9182L16.6122 11.1199C16.6122 11.1199 16.6419 11.0486 16.5952 11.0276C16.5443 11.0108 16.5188 11.0779 16.5188 11.0779L14.809 14.7587M15.8612 13.7766C16.277 13.3989 16.2515 12.9372 16.6376 12.6141C17.0322 12.2909 17.0364 11.9006 17.1255 11.7117C17.2231 11.5312 17.4013 11.548 17.3801 11.4851C17.3589 11.4221 17.1637 11.4473 16.9898 11.6697C16.8243 11.8922 16.9134 12.0978 16.4425 12.4965C15.9715 12.8953 16.0818 13.2058 15.6364 13.5458"
          fill="#F6B40E"
        />
        <path
          d="M15.1909 14.9182L16.6122 11.1199C16.6122 11.1199 16.6419 11.0486 16.5952 11.0276C16.5443 11.0108 16.5188 11.0779 16.5188 11.0779L14.809 14.7587M15.8612 13.7766C16.277 13.3989 16.2515 12.9372 16.6376 12.6141C17.0322 12.2909 17.0364 11.9006 17.1255 11.7117C17.2231 11.5312 17.4013 11.548 17.3801 11.4851C17.3589 11.4221 17.1637 11.4473 16.9898 11.6697C16.8243 11.8922 16.9134 12.0978 16.4425 12.4965C15.9715 12.8953 16.0818 13.2058 15.6364 13.5458"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M15.1453 14.9839L17.9278 12.0128C17.9278 12.0128 17.9828 11.9581 17.9478 11.9211C17.9073 11.8863 17.8578 11.9387 17.8578 11.9387L14.8543 14.692M16.2063 14.183C16.7365 13.9914 16.8916 13.5552 17.3733 13.4028C17.8629 13.2536 18.0178 12.8946 18.1731 12.7539C18.3331 12.6241 18.4912 12.707 18.496 12.6408C18.5008 12.5747 18.3107 12.524 18.064 12.6637C17.825 12.8066 17.8278 13.0303 17.2385 13.2204C16.6491 13.4105 16.6309 13.7392 16.0878 13.8846"
          fill="#F6B40E"
        />
        <path
          d="M15.1453 14.9839L17.9278 12.0128C17.9278 12.0128 17.9828 11.9581 17.9478 11.9211C17.9073 11.8863 17.8578 11.9387 17.8578 11.9387L14.8543 14.692M16.2063 14.183C16.7365 13.9914 16.8916 13.5552 17.3733 13.4028C17.8629 13.2536 18.0178 12.8946 18.1731 12.7539C18.3331 12.6241 18.4912 12.707 18.496 12.6408C18.5008 12.5747 18.3107 12.524 18.064 12.6637C17.825 12.8066 17.8278 13.0303 17.2385 13.2204C16.6491 13.4105 16.6309 13.7392 16.0878 13.8846"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M14.7417 13.3911C14.7117 12.8569 14.4057 12.6195 14.4657 12.1269C14.3337 12.5127 14.6517 12.8154 14.6337 13.3852M15.0957 14.7978L13.9257 12.2693L14.9037 14.875"
          fill="#85340A"
        />
        <path
          d="M15.3217 13.4013C15.5006 12.8964 15.3097 12.5612 15.5558 12.1288C15.2846 12.4353 15.4613 12.8353 15.2242 13.3549M15.1046 14.835L15.0018 12.056L14.8973 14.8336"
          fill="#85340A"
        />
        <path
          d="M15.8528 13.6328C16.2134 13.2341 16.1668 12.8522 16.5613 12.5458C16.1922 12.7263 16.2007 13.1627 15.7807 13.5531M15.0976 14.8751L16.0777 12.2688L14.9067 14.7954"
          fill="#85340A"
        />
        <path
          d="M16.2538 14.0461C16.7412 13.8143 16.8458 13.4437 17.3289 13.3101C16.918 13.3371 16.757 13.7435 16.218 13.9451M15.0755 14.908L16.9892 12.871L14.9299 14.762"
          fill="#85340A"
        />
        <path
          d="M15.0784 15.0312L18.7984 13.3396C18.7984 13.3396 18.8704 13.31 18.8524 13.2625C18.8284 13.215 18.7624 13.2447 18.7624 13.2447L14.9224 14.6514M16.3684 14.6929C16.9324 14.7167 17.2444 14.3724 17.7484 14.414C18.2584 14.4614 18.5404 14.1884 18.7384 14.1172C18.9364 14.0578 19.0504 14.1943 19.0804 14.135C19.1104 14.0756 18.9544 13.9569 18.6724 13.9925C18.3964 14.0341 18.3124 14.2418 17.6944 14.1943C17.0764 14.1469 16.9324 14.4436 16.3744 14.3724"
          fill="#F6B40E"
        />
        <path
          d="M15.0784 15.0312L18.7984 13.3396C18.7984 13.3396 18.8704 13.31 18.8524 13.2625C18.8284 13.215 18.7624 13.2447 18.7624 13.2447L14.9224 14.6514M16.3684 14.6929C16.9324 14.7167 17.2444 14.3724 17.7484 14.414C18.2584 14.4614 18.5404 14.1884 18.7384 14.1172C18.9364 14.0578 19.0504 14.1943 19.0804 14.135C19.1104 14.0756 18.9544 13.9569 18.6724 13.9925C18.3964 14.0341 18.3124 14.2418 17.6944 14.1943C17.0764 14.1469 16.9324 14.4436 16.3744 14.3724"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M14.9986 15.0434L19.0898 14.8889C19.0898 14.8889 19.1678 14.8887 19.1696 14.838C19.1658 14.7851 19.0933 14.7875 19.0933 14.7875L15.0014 14.6334M16.3213 15.2192C16.8332 15.4547 17.2546 15.2547 17.7042 15.4839C18.157 15.7208 18.5231 15.5754 18.7336 15.5845C18.9395 15.6046 18.992 15.7739 19.0427 15.7304C19.0934 15.6869 18.9952 15.5182 18.7209 15.4444C18.4498 15.3783 18.2918 15.5384 17.7392 15.2606C17.1866 14.9827 16.9388 15.2024 16.4508 14.9254"
          fill="#F6B40E"
        />
        <path
          d="M14.9986 15.0434L19.0898 14.8889C19.0898 14.8889 19.1678 14.8887 19.1696 14.838C19.1658 14.7851 19.0933 14.7875 19.0933 14.7875L15.0014 14.6334M16.3213 15.2192C16.8332 15.4547 17.2546 15.2547 17.7042 15.4839C18.157 15.7208 18.5231 15.5754 18.7336 15.5845C18.9395 15.6046 18.992 15.7739 19.0427 15.7304C19.0934 15.6869 18.9952 15.5182 18.7209 15.4444C18.4498 15.3783 18.2918 15.5384 17.7392 15.2606C17.1866 14.9827 16.9388 15.2024 16.4508 14.9254"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M14.9194 15.0271L18.759 16.4331C18.759 16.4331 18.8311 16.4625 18.8523 16.4163C18.8693 16.3659 18.8014 16.3408 18.8014 16.3408L15.0806 14.6494M16.0734 15.6902C16.4552 16.1015 16.9219 16.0763 17.2486 16.4583C17.5753 16.8486 17.9698 16.8528 18.1608 16.9409C18.3432 17.0375 18.3262 17.2137 18.3899 17.1928C18.4535 17.1718 18.4281 16.9787 18.2032 16.8066C17.9783 16.6429 17.7704 16.7311 17.3674 16.2652C16.9643 15.7993 16.6504 15.9085 16.3067 15.4678"
          fill="#F6B40E"
        />
        <path
          d="M14.9194 15.0271L18.759 16.4331C18.759 16.4331 18.8311 16.4625 18.8523 16.4163C18.8693 16.3659 18.8014 16.3408 18.8014 16.3408L15.0806 14.6494M16.0734 15.6902C16.4552 16.1015 16.9219 16.0763 17.2486 16.4583C17.5753 16.8486 17.9698 16.8528 18.1608 16.9409C18.3432 17.0375 18.3262 17.2137 18.3899 17.1928C18.4535 17.1718 18.4281 16.9787 18.2032 16.8066C17.9783 16.6429 17.7704 16.7311 17.3674 16.2652C16.9643 15.7993 16.6504 15.9085 16.3067 15.4678"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M14.8527 14.9824L17.8561 17.7349C17.8561 17.7349 17.9114 17.7894 17.9488 17.7548C17.984 17.7147 17.931 17.6657 17.931 17.6657L15.1477 14.6945M15.6623 16.0319C15.856 16.5565 16.2969 16.7099 16.4509 17.1864C16.6018 17.6707 16.9647 17.824 17.107 17.9777C17.2382 18.1359 17.1543 18.2923 17.2212 18.297C17.2881 18.3017 17.3393 18.1137 17.1981 17.8696C17.0537 17.6333 16.8275 17.636 16.6354 17.053C16.4432 16.47 16.1109 16.452 15.9639 15.9148"
          fill="#F6B40E"
        />
        <path
          d="M14.8527 14.9824L17.8561 17.7349C17.8561 17.7349 17.9114 17.7894 17.9488 17.7548C17.984 17.7147 17.931 17.6657 17.931 17.6657L15.1477 14.6945M15.6623 16.0319C15.856 16.5565 16.2969 16.7099 16.4509 17.1864C16.6018 17.6707 16.9647 17.824 17.107 17.9777C17.2382 18.1359 17.1543 18.2923 17.2212 18.297C17.2881 18.3017 17.3393 18.1137 17.1981 17.8696C17.0537 17.6333 16.8275 17.636 16.6354 17.053C16.4432 16.47 16.1109 16.452 15.9639 15.9148"
          stroke="#85340A"
          stroke-width="0.132"
        />
        <path
          d="M16.4644 14.5834C17.0044 14.5537 17.2444 14.251 17.7424 14.3104C17.3524 14.1798 17.0464 14.4944 16.4704 14.4766M15.0424 14.9336L17.5984 13.7762L14.9644 14.7437"
          fill="#85340A"
        />
        <path
          d="M16.4515 15.1584C16.9618 15.3354 17.3007 15.1466 17.7378 15.3899C17.428 15.1217 17.0236 15.2965 16.4983 15.0619M15.0022 14.9436L17.8114 14.8419L15.0037 14.7386"
          fill="#85340A"
        />
        <path
          d="M16.2177 15.6844C16.6208 16.0412 17.0068 15.995 17.3166 16.3853C17.1341 16.0202 16.6929 16.0286 16.2983 15.6131M14.9619 14.9374L17.5966 15.9069L15.0425 14.7485"
          fill="#85340A"
        />
        <path
          d="M15.799 16.0807C16.0334 16.5628 16.4079 16.6663 16.5431 17.1442C16.5158 16.7378 16.1049 16.5785 15.9011 16.0453M14.9278 14.915L16.9869 16.8082L15.0753 14.7711"
          fill="#85340A"
        />
        <path
          d="M15 16.4891C15.9217 16.4891 16.6688 15.75 16.6688 14.8383C16.6688 13.9266 15.9217 13.1875 15 13.1875C14.0784 13.1875 13.3313 13.9266 13.3313 14.8383C13.3313 15.75 14.0784 16.4891 15 16.4891Z"
          fill="#F6B40E"
          stroke="#85340A"
          stroke-width="0.175"
        />
        <path
          d="M15.5688 14.4844C15.4563 14.4844 15.3438 14.5338 15.2812 14.6328C15.4062 14.7502 15.6938 14.7564 15.8875 14.6204C15.8466 14.5774 15.7972 14.5432 15.7423 14.5198C15.6875 14.4964 15.6285 14.4843 15.5688 14.4844ZM15.5688 14.5091C15.675 14.5091 15.7813 14.5586 15.7938 14.608C15.6688 14.7502 15.4625 14.7317 15.3312 14.6328C15.3564 14.5934 15.3916 14.5612 15.4333 14.5395C15.475 14.5178 15.5217 14.5073 15.5688 14.5091Z"
          fill="#843511"
        />
        <path
          d="M15.5875 14.4062C15.4187 14.4062 15.3812 14.4495 15.3062 14.5114C15.2312 14.5732 15.1875 14.5608 15.1812 14.5732C15.1625 14.5856 15.1812 14.6226 15.2 14.6103C15.2312 14.5979 15.2812 14.5732 15.3562 14.5114C15.4312 14.4495 15.5125 14.4495 15.5875 14.4495C15.8187 14.4495 15.9437 14.635 15.975 14.6226C15.9937 14.6103 15.8437 14.4062 15.5875 14.4062Z"
          fill="#85340A"
        />
        <path
          d="M16.05 14.4861C15.7563 14.2326 15.3813 14.1893 15.175 14.381C15.1266 14.4446 15.0945 14.5188 15.0813 14.5973C15.0563 14.7396 15.1 14.8941 15.2125 15.0549L15.1625 15.0796C15.0644 14.9024 15.0292 14.6978 15.0625 14.4984C15.0707 14.45 15.0833 14.4024 15.1 14.3562C15.3813 14.1275 15.7688 14.1089 16.05 14.4799V14.4861Z"
          fill="#85340A"
        />
        <path
          d="M15.5626 14.7271C15.6282 14.7271 15.6813 14.6745 15.6813 14.6097C15.6813 14.5448 15.6282 14.4922 15.5626 14.4922C15.497 14.4922 15.4438 14.5448 15.4438 14.6097C15.4438 14.6745 15.497 14.7271 15.5626 14.7271Z"
          fill="#85340A"
        />
        <path
          d="M15.8936 14.6849C15.6748 14.8518 15.4561 14.8395 15.3311 14.7653C15.2061 14.6849 15.2061 14.6602 15.2311 14.6602C15.2561 14.6602 15.2811 14.6849 15.3811 14.7405C15.4873 14.79 15.6373 14.79 15.8936 14.6849Z"
          fill="#85340A"
        />
        <path
          d="M14.7502 15.0687C14.7301 15.0707 14.7109 15.0777 14.6944 15.0891C14.6778 15.1004 14.6645 15.1158 14.6556 15.1337C14.6467 15.1516 14.6425 15.1714 14.6435 15.1913C14.6445 15.2112 14.6506 15.2306 14.6612 15.2475C14.6718 15.2645 14.6866 15.2784 14.7042 15.2882C14.7218 15.2979 14.7417 15.303 14.7618 15.303C14.782 15.3031 14.8018 15.2981 14.8195 15.2884C14.8371 15.2788 14.852 15.2649 14.8627 15.248C14.9039 15.2733 14.9517 15.2862 15.0002 15.2851H15.0127C15.0633 15.2878 15.1136 15.2748 15.1564 15.248C15.168 15.2637 15.1833 15.2763 15.201 15.2847C15.2186 15.2931 15.2381 15.2971 15.2577 15.2962C15.2773 15.2953 15.2964 15.2897 15.3132 15.2797C15.3301 15.2698 15.3442 15.2559 15.3543 15.2393C15.3644 15.2226 15.3701 15.2038 15.3711 15.1844C15.372 15.165 15.3681 15.1457 15.3596 15.1282C15.3512 15.1107 15.3384 15.0955 15.3226 15.0841C15.3068 15.0726 15.2884 15.0652 15.2689 15.0625C15.3002 15.081 15.3189 15.1058 15.3189 15.1429C15.3189 15.18 15.2877 15.2171 15.2439 15.2171C15.2241 15.2171 15.205 15.2093 15.1909 15.1953C15.1768 15.1814 15.1689 15.1626 15.1689 15.1429C15.1541 15.1734 15.1312 15.1993 15.1025 15.2179C15.0739 15.2364 15.0406 15.2468 15.0064 15.248C14.9733 15.2457 14.9414 15.2348 14.914 15.2163C14.8865 15.1979 14.8645 15.1725 14.8502 15.1429C14.8502 15.1532 14.8481 15.1633 14.844 15.1728C14.8398 15.1822 14.8337 15.1907 14.8261 15.1977C14.8184 15.2046 14.8094 15.21 14.7996 15.2133C14.7897 15.2166 14.7793 15.2179 14.7689 15.2171C14.7314 15.2171 14.6939 15.18 14.6939 15.1429C14.6939 15.1058 14.7127 15.081 14.7439 15.0687H14.7502ZM14.8752 15.4087C14.7439 15.4087 14.6877 15.52 14.5752 15.5942C14.6377 15.5695 14.6939 15.52 14.7814 15.4706C14.8689 15.4211 14.9502 15.4829 15.0002 15.4829C15.0502 15.4829 15.1252 15.4211 15.2189 15.4706C15.3064 15.52 15.3627 15.5695 15.4252 15.5942C15.3064 15.52 15.2564 15.4087 15.1252 15.4087C15.0815 15.4131 15.0391 15.4257 15.0002 15.4458C14.9618 15.4235 14.9193 15.4088 14.8752 15.4026V15.4087Z"
          fill="#85340A"
        />
        <path
          d="M14.8248 15.5546C14.7748 15.5546 14.7123 15.5669 14.6123 15.5917C14.8436 15.5422 14.8936 15.6226 14.9998 15.6226C15.0998 15.6226 15.1498 15.5422 15.3811 15.5917C15.1311 15.5175 15.0748 15.5669 14.9998 15.5669C14.9498 15.5669 14.9123 15.5484 14.8248 15.5546Z"
          fill="#85340A"
        />
        <path
          d="M14.6252 15.5923H14.5752C14.8439 15.6233 14.7189 15.7778 15.0002 15.7778C15.2814 15.7778 15.1564 15.6233 15.4252 15.5923C15.1439 15.5676 15.2314 15.7345 15.0002 15.7345C14.7814 15.7345 14.8502 15.5923 14.6252 15.5923ZM15.2314 16.0066C15.2314 15.9459 15.2071 15.8877 15.1637 15.8448C15.1203 15.8019 15.0615 15.7778 15.0002 15.7778C14.9389 15.7778 14.88 15.8019 14.8367 15.8448C14.7933 15.8877 14.7689 15.9459 14.7689 16.0066C14.7812 15.955 14.8106 15.909 14.8526 15.876C14.8946 15.8431 14.9466 15.8252 15.0002 15.8252C15.0538 15.8252 15.1058 15.8431 15.1478 15.876C15.1897 15.909 15.2192 15.955 15.2314 16.0066Z"
          fill="#85340A"
        />
        <path
          d="M13.9624 14.4861C14.2562 14.2326 14.6312 14.1893 14.8374 14.381C14.8858 14.4446 14.918 14.5188 14.9312 14.5973C14.9562 14.7396 14.9124 14.8941 14.7999 15.0549L14.8499 15.0796C14.948 14.9024 14.9833 14.6978 14.9499 14.4984C14.9417 14.45 14.9292 14.4024 14.9124 14.3562C14.6312 14.1275 14.2437 14.1089 13.9624 14.4799V14.4861Z"
          fill="#85340A"
        />
        <path
          d="M14.425 14.4062C14.5937 14.4062 14.6312 14.4495 14.7062 14.5114C14.7812 14.5732 14.825 14.5608 14.8312 14.5732C14.85 14.5856 14.8312 14.6226 14.8125 14.6103C14.7812 14.5979 14.7312 14.5732 14.6562 14.5114C14.5812 14.4495 14.5 14.4495 14.425 14.4495C14.1937 14.4495 14.0687 14.635 14.0375 14.6226C14.0187 14.6103 14.1687 14.4062 14.425 14.4062Z"
          fill="#85340A"
        />
        <path
          d="M14.4188 14.4844C14.3063 14.4844 14.1938 14.5338 14.1313 14.6328C14.2563 14.7502 14.5438 14.7564 14.7376 14.6204C14.6967 14.5774 14.6473 14.5432 14.5924 14.5198C14.5376 14.4964 14.4785 14.4843 14.4188 14.4844ZM14.4188 14.5091C14.5251 14.5091 14.6313 14.5586 14.6438 14.608C14.5188 14.7502 14.3126 14.7317 14.1813 14.6328C14.2065 14.5934 14.2417 14.5612 14.2834 14.5395C14.3251 14.5178 14.3718 14.5073 14.4188 14.5091Z"
          fill="#843511"
        />
        <path
          d="M14.4312 14.7271C14.4968 14.7271 14.55 14.6745 14.55 14.6097C14.55 14.5448 14.4968 14.4922 14.4312 14.4922C14.3657 14.4922 14.3125 14.5448 14.3125 14.6097C14.3125 14.6745 14.3657 14.7271 14.4312 14.7271Z"
          fill="#85340A"
        />
        <path
          d="M14.1187 14.6849C14.3374 14.8518 14.5562 14.8395 14.6812 14.7653C14.8062 14.6849 14.8062 14.6602 14.7812 14.6602C14.7562 14.6602 14.7312 14.6849 14.6312 14.7405C14.5249 14.79 14.3749 14.79 14.1187 14.6849Z"
          fill="#85340A"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3190_4513">
        <rect width="30" height="30" rx="15" fill="white" />
      </clipPath>
      <clipPath id="clip1_3190_4513">
        <rect width="40" height="32" fill="white" transform="translate(-5)" />
      </clipPath>
    </defs>
  </svg>
);
export default RoundArgentinianFLag;
