const LogoBuydepaWhiteBgBlue: React.FC<{
  width?: string;
  height?: string;
  className?: string;
}> = ({ width = 753, height = 917, className = '' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 753 917"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_1550_6337)">
      <ellipse cx="561" cy="381.5" rx="161" ry="50.5" fill="#F2006E" />
    </g>
    <g filter="url(#filter1_f_1550_6337)">
      <ellipse cx="477.5" cy="453" rx="77.5" ry="64" fill="#3A5AFF" />
    </g>
    <path
      d="M175.99 523.294V442.589H183.439V475.018C186.527 471.149 189.979 468.201 193.794 466.358C197.609 464.516 201.787 463.41 206.329 463.41C214.504 463.41 221.408 466.358 227.221 472.439C233.034 478.335 235.759 485.705 235.759 494.181C235.759 502.657 232.853 509.843 227.039 515.739C221.226 521.635 214.322 524.768 206.147 524.768C201.424 524.768 197.246 523.662 193.43 521.635C189.615 519.608 186.345 516.476 183.439 512.422V523.109H175.99V523.294ZM205.602 517.582C209.781 517.582 213.414 516.476 217.047 514.449C220.499 512.422 223.224 509.474 225.404 505.789C227.403 502.104 228.493 498.235 228.493 493.997C228.493 489.943 227.403 485.889 225.404 482.204C223.406 478.519 220.499 475.571 217.047 473.36C213.596 471.333 209.781 470.227 205.784 470.227C201.787 470.227 197.972 471.333 194.339 473.36C190.705 475.387 187.98 478.335 185.982 481.836C183.984 485.337 183.075 489.39 183.075 493.812C183.075 500.63 185.255 506.158 189.615 510.764C193.794 515.371 199.062 517.582 205.602 517.582Z"
      fill="white"
    />
    <path
      d="M235.395 465.069H242.843V492.339C242.843 498.973 243.206 503.579 243.933 505.974C245.023 509.475 247.021 512.424 249.928 514.45C252.835 516.477 256.468 517.583 260.647 517.583C264.825 517.583 268.277 516.661 271.183 514.635C274.09 512.608 276.088 510.028 277.178 506.712C277.905 504.5 278.268 499.71 278.268 492.339V465.069H285.899V493.629C285.899 501.737 284.99 507.633 283.174 511.871C281.357 515.924 278.632 519.057 274.817 521.452C271.183 523.663 266.46 524.953 260.828 524.953C255.196 524.953 250.473 523.847 246.84 521.452C243.025 519.241 240.3 515.924 238.483 511.871C236.666 507.817 235.758 501.552 235.758 493.261V465.069H235.395Z"
      fill="white"
    />
    <path
      d="M282.814 465.069H290.626L310.428 509.844L329.503 465.069H337.315L302.98 544.669H295.168L306.25 518.873L282.814 465.069Z"
      fill="white"
    />
    <path
      d="M374.007 446.824H387.814V523.66H374.007V517.763C371.282 520.343 368.557 522.186 366.013 523.291C363.288 524.397 360.382 524.949 357.293 524.949C350.208 524.949 344.031 522.186 338.945 516.658C333.858 511.13 331.133 504.128 331.133 496.021C331.133 487.545 333.676 480.359 338.581 475.016C343.486 469.488 349.663 466.724 356.748 466.724C360.018 466.724 363.107 467.277 366.013 468.567C368.92 469.856 371.463 471.699 374.007 474.279V446.824ZM359.655 479.806C355.476 479.806 351.843 481.28 349.118 484.413C346.393 487.545 344.94 491.415 344.94 496.205C344.94 500.996 346.393 504.865 349.3 507.998C352.206 511.13 355.658 512.604 359.837 512.604C364.197 512.604 367.648 511.13 370.555 507.998C373.462 504.865 374.733 500.996 374.733 496.021C374.733 491.23 373.28 487.361 370.555 484.228C367.467 481.28 364.015 479.806 359.655 479.806Z"
      fill="white"
    />
    <path
      d="M448.124 500.071H403.979C404.705 503.941 406.34 507.073 409.065 509.468C411.79 511.864 415.424 512.969 419.602 512.969C424.689 512.969 429.049 511.127 432.864 507.442L444.491 512.969C441.584 517.207 438.132 520.155 434.136 522.182C430.139 524.209 425.416 525.13 419.784 525.13C411.245 525.13 404.16 522.366 398.892 516.839C393.442 511.311 390.717 504.493 390.717 496.202C390.717 487.726 393.442 480.724 398.71 475.197C404.16 469.669 410.882 466.721 418.876 466.721C427.414 466.721 434.499 469.485 439.949 475.197C445.399 480.724 448.124 488.095 448.124 497.307V500.071ZM434.499 489.2C433.591 486.068 431.774 483.672 429.049 481.646C426.324 479.619 423.236 478.697 419.784 478.697C415.969 478.697 412.517 479.803 409.792 482.014C407.975 483.304 406.34 485.699 404.705 489.2H434.499Z"
      fill="white"
    />
    <path
      d="M464.66 468.385V474.466C467.204 471.886 469.747 470.044 472.654 468.754C475.561 467.464 478.649 466.911 481.919 466.911C489.004 466.911 495.181 469.675 500.086 475.203C504.991 480.73 507.534 487.732 507.534 496.208C507.534 504.5 504.991 511.317 499.723 516.845C494.636 522.373 488.459 525.136 481.374 525.136C478.286 525.136 475.379 524.584 472.654 523.478C469.929 522.373 467.204 520.53 464.479 517.95V532.875H450.854V468.385H464.66ZM479.194 479.809C474.834 479.809 471.2 481.283 468.475 484.231C465.569 487.179 464.115 491.049 464.115 496.024C464.115 500.999 465.569 504.868 468.475 508.001C471.382 511.133 474.834 512.607 479.194 512.607C483.372 512.607 486.824 511.133 489.731 508.001C492.637 504.868 494.091 500.999 494.091 496.208C494.091 491.417 492.637 487.548 489.912 484.416C487.006 481.283 483.372 479.809 479.194 479.809Z"
      fill="white"
    />
    <path
      d="M551.132 468.38H564.939V523.842H551.132V517.946C548.407 520.525 545.682 522.368 543.138 523.473C540.413 524.579 537.507 525.132 534.418 525.132C527.333 525.132 521.156 522.368 516.07 516.84C510.983 511.312 508.258 504.311 508.258 496.203C508.258 487.727 510.801 480.541 515.706 475.198C520.611 469.67 526.788 466.906 533.873 466.906C537.143 466.906 540.232 467.459 543.138 468.749C546.045 470.039 548.588 471.881 551.132 474.461V468.38ZM536.78 479.804C532.601 479.804 528.968 481.278 526.243 484.411C523.518 487.543 522.065 491.412 522.065 496.203C522.065 500.994 523.518 504.863 526.425 507.996C529.331 511.128 532.783 512.602 536.962 512.602C541.322 512.602 544.773 511.128 547.68 507.996C550.587 504.863 551.858 500.994 551.858 496.019C551.858 491.228 550.405 487.359 547.68 484.226C544.592 481.278 541.14 479.804 536.78 479.804Z"
      fill="white"
    />
    <path d="M569.852 518.323H574.938V523.667H569.852V518.323Z" fill="white" />
    <path
      d="M576.758 513.532C576.758 507.636 581.3 502.661 587.476 502.661C591.291 502.661 593.653 504.135 595.651 506.162L592.745 509.294C591.291 507.82 589.838 506.715 587.476 506.715C584.025 506.715 581.481 509.663 581.481 513.348C581.481 517.033 584.025 519.981 587.658 519.981C589.838 519.981 591.473 518.876 592.926 517.402L595.833 520.166C593.835 522.377 591.473 524.035 587.476 524.035C581.3 524.219 576.758 519.429 576.758 513.532Z"
      fill="white"
    />
    <path
      d="M596.371 513.532C596.371 507.452 601.094 502.661 607.271 502.661C613.448 502.661 618.171 507.452 618.171 513.348C618.171 519.244 613.448 524.219 607.271 524.219C601.094 524.219 596.371 519.429 596.371 513.532ZM613.448 513.532C613.448 509.847 610.905 506.715 607.271 506.715C603.456 506.715 601.094 509.663 601.094 513.348C601.094 517.033 603.638 519.981 607.271 519.981C611.086 520.166 613.448 517.033 613.448 513.532Z"
      fill="white"
    />
    <path
      d="M621.078 503.03H625.802V506.162C627.073 504.319 628.89 502.661 631.978 502.661C634.885 502.661 637.065 504.135 638.155 506.346C639.79 504.135 641.97 502.661 645.058 502.661C649.418 502.661 652.143 505.609 652.143 510.584V523.851H647.42V512.058C647.42 508.742 645.967 507.083 643.242 507.083C640.698 507.083 638.882 508.926 638.882 512.243V523.851H634.158V512.058C634.158 508.926 632.523 507.083 629.98 507.083C627.437 507.083 625.62 509.11 625.62 512.243V523.851H620.896V503.03H621.078Z"
      fill="white"
    />
    <path d="M114.589 469.673H105.688V477.596H114.589V469.673Z" fill="white" />
    <path d="M114.589 482.574H105.688V490.497H114.589V482.574Z" fill="white" />
    <path d="M114.589 495.472H105.688V503.395H114.589V495.472Z" fill="white" />
    <path d="M100.593 482.574H91.6914V490.497H100.593V482.574Z" fill="white" />
    <path d="M100.593 495.472H91.6914V503.395H100.593V495.472Z" fill="white" />
    <path
      d="M77.707 470.965C90.4239 448.669 118.401 441.115 140.383 453.829C147.105 457.882 152.736 463.226 156.37 469.675V468.569C156.37 460.83 152.373 453.644 145.651 449.775L115.131 431.902C108.591 428.033 100.416 428.033 93.8756 431.902L63.3551 449.959C56.8151 453.829 53 461.015 53 468.754V504.684C53 512.423 56.8151 519.609 63.3551 523.478L93.8756 541.535C100.416 545.405 108.591 545.405 115.131 541.535L116.039 540.982C108.772 540.798 101.324 538.771 94.6022 534.718C72.6203 521.635 64.9902 493.076 77.707 470.965Z"
      fill="white"
    />
    <path
      d="M145.833 449.959L115.131 431.902C108.591 428.033 100.416 428.033 93.8756 431.902L63.3551 449.959C56.8151 453.829 53 461.015 53 468.754V469.859C54.8167 463.594 62.0835 458.067 68.8052 454.013C90.7872 441.115 118.764 448.854 131.481 471.149C144.198 493.444 136.568 521.82 114.586 534.718C111.316 536.56 108.046 538.034 104.594 539.14C108.409 540.245 112.224 540.982 116.039 540.982L145.833 523.478C152.373 519.609 156.37 512.423 156.37 504.684V468.569C156.551 460.83 152.373 453.829 145.833 449.959Z"
      fill="white"
    />
    <path d="M64.0783 449.402L63.1699 449.771L64.0783 449.402Z" fill="white" />
    <path
      d="M145.832 449.955L144.923 449.402C148.375 456.036 150.374 463.406 150.374 471.329C150.374 497.125 129.845 517.946 104.411 517.946C92.0577 517.946 80.9759 513.155 72.8008 505.048C75.7075 517.025 83.1559 527.712 94.4194 534.529C101.141 538.583 108.59 540.61 115.856 540.794L114.948 541.347L145.65 523.29C152.19 519.42 156.369 512.234 156.369 504.495V468.565C156.55 460.826 152.372 453.825 145.832 449.955Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_f_1550_6337"
        x="0"
        y="-69"
        width="1122"
        height="901"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="200"
          result="effect1_foregroundBlur_1550_6337"
        />
      </filter>
      <filter
        id="filter1_f_1550_6337"
        x="0"
        y="-11"
        width="955"
        height="928"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="200"
          result="effect1_foregroundBlur_1550_6337"
        />
      </filter>
    </defs>
  </svg>
);

export default LogoBuydepaWhiteBgBlue;
