export const ProfileIcon = ({ size = 248 }: { size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 248 248"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M248 124C248 156.887 234.936 188.427 211.681 211.681C188.427 234.936 156.887 248 124 248C91.1132 248 59.5733 234.936 36.3188 211.681C13.0642 188.427 0 156.887 0 124C0 91.1132 13.0642 59.5733 36.3188 36.3188C59.5733 13.0642 91.1132 0 124 0C156.887 0 188.427 13.0642 211.681 36.3188C234.936 59.5733 248 91.1132 248 124ZM155 77.5C155 85.7217 151.734 93.6067 145.92 99.4203C140.107 105.234 132.222 108.5 124 108.5C115.778 108.5 107.893 105.234 102.08 99.4203C96.2661 93.6067 93 85.7217 93 77.5C93 69.2783 96.2661 61.3933 102.08 55.5797C107.893 49.7661 115.778 46.5 124 46.5C132.222 46.5 140.107 49.7661 145.92 55.5797C151.734 61.3933 155 69.2783 155 77.5ZM124 139.5C109.16 139.497 94.6318 143.755 82.1409 151.767C69.6501 159.779 59.7222 171.209 53.537 184.698C62.2585 194.844 73.0712 202.984 85.2336 208.56C97.3961 214.135 110.62 217.015 124 217C137.38 217.015 150.604 214.135 162.766 208.56C174.929 202.984 185.742 194.844 194.463 184.698C188.278 171.209 178.35 159.779 165.859 151.767C153.368 143.755 138.84 139.497 124 139.5Z"
        fill="#060C41"
        fillOpacity="0.45"
      />
    </svg>
  );
};
