/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Listbox, Transition } from '@headlessui/react';
import cn from 'classnames';

interface Dropdown2 {
  className?: string;
  children?: React.ReactNode;
  customIcon?: React.ReactNode;
  options: any;
  selected: any;
  color?: 'white' | 'default' | 'primary';
  setSelected: any;
  width?: number;
  height?: number;
}

export function Dropdown2({
  customIcon,
  options,
  color = 'default',
  selected,
  setSelected,
  width = 25,
  height = 25,
}: Dropdown2) {
  const rootColor = cn({
    'border border-white hover:shadow-md text-white': color === 'default',
    'text-primary-600 border border-primary-600': color === 'primary',
    'bg-primary-600 text-white': color === 'white',
  });
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Button
            className={`cursor-pointer ${rootColor} rounded-full p-1 `}
          >
            {customIcon || (
              <EllipsisVerticalIcon width={width} height={height} />
            )}
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute right-8 top-4 z-10 mt-1 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option: any, index: number) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    cn(
                      active ? 'text-gray-900' : 'text-gray-500',
                      'relative cursor-pointer select-none py-2 pr-4'
                    )
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <span
                      className={cn(
                        selected ? 'font-semibold' : 'font-normal',
                        'flex items-center justify-left gap-4 ml-5'
                      )}
                    >
                      {option.icon}
                      {option.label}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  );
}

export default Dropdown2;
