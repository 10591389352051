const HandMoney = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      zoomAndPan="magnify"
      viewBox="0 0 46.5 43.499998"
      height="58"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="1345ae8d1e">
          <path
            d="M 0.015625 0 L 45.988281 0 L 45.988281 43.003906 L 0.015625 43.003906 Z M 0.015625 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="43be0001db">
          <path
            d="M 1.695312 1.679688 L 42 1.679688 L 42 41.324219 L 1.695312 41.324219 Z M 1.695312 1.679688 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="c0efcd0678">
          <path
            d="M 35.765625 2.449219 L 7.992188 2.449219 C 4.972656 2.449219 2.464844 5.175781 2.464844 8.617188 L 2.113281 8.617188 L 2.464844 8.617188 L 2.464844 34.386719 L 2.113281 34.386719 L 2.464844 34.386719 C 2.464844 37.828125 4.972656 40.558594 7.992188 40.558594 L 7.992188 40.90625 L 7.992188 40.558594 L 35.765625 40.558594 L 35.765625 40.90625 L 35.765625 40.558594 C 38.78125 40.558594 41.292969 37.832031 41.292969 34.386719 L 41.640625 34.386719 L 41.292969 34.386719 L 41.292969 8.617188 L 41.640625 8.617188 L 41.292969 8.617188 C 41.292969 5.175781 38.78125 2.449219 35.765625 2.449219 M 35.765625 1.75 C 39.238281 1.75 41.992188 4.859375 41.992188 8.617188 L 41.992188 34.386719 C 41.992188 38.144531 39.238281 41.257812 35.765625 41.257812 L 7.992188 41.257812 C 4.519531 41.257812 1.765625 38.144531 1.765625 34.386719 L 1.765625 8.617188 C 1.765625 4.859375 4.519531 1.75 7.992188 1.75 Z M 35.765625 1.75 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="77f38e2ad1">
          <path
            d="M 17.152344 7.894531 L 31.765625 7.894531 L 31.765625 22 L 17.152344 22 Z M 17.152344 7.894531 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="905a9b29b9">
          <path
            d="M 25.386719 18.878906 L 25.816406 18.714844 C 26.199219 18.480469 26.382812 18.335938 26.53125 18.171875 C 27.402344 17.207031 27.226562 15.769531 26.148438 14.960938 L 23.945312 13.332031 C 23.738281 13.175781 23.621094 12.984375 23.703125 12.722656 L 24 12.347656 C 24.441406 12.34375 24.617188 12.34375 24.785156 12.34375 C 25.242188 12.347656 25.402344 12.492188 25.460938 12.941406 C 25.519531 13.402344 25.90625 13.726562 26.339844 13.679688 C 26.78125 13.636719 27.097656 13.253906 27.082031 12.796875 C 27.046875 11.90625 26.484375 11.136719 25.640625 10.847656 L 25.378906 10.699219 C 25.34375 10.105469 25.023438 9.804688 24.648438 9.773438 C 24.253906 9.738281 23.84375 9.972656 23.808594 10.339844 C 23.773438 10.710938 23.585938 10.847656 23.28125 10.925781 L 23.25 10.941406 C 21.792969 11.679688 21.632812 13.601562 22.9375 14.601562 L 25.183594 16.265625 C 25.394531 16.425781 25.519531 16.628906 25.421875 16.898438 L 25.117188 17.269531 C 24.65625 17.269531 24.46875 17.273438 24.28125 17.269531 C 23.902344 17.265625 23.71875 17.097656 23.675781 16.722656 C 23.621094 16.230469 23.261719 15.917969 22.800781 15.949219 C 22.335938 15.984375 22.007812 16.375 22.054688 16.871094 C 22.136719 17.769531 22.589844 18.414062 23.4375 18.746094 L 23.746094 18.90625 C 23.804688 19.574219 24.179688 19.863281 24.589844 19.847656 C 25.011719 19.828125 25.328125 19.519531 25.367188 19.085938 L 25.378906 18.941406 M 25.136719 7.929688 L 26.769531 8.304688 C 29.714844 9.300781 31.617188 12.140625 31.429688 15.234375 C 31.242188 18.3125 28.992188 20.90625 25.957031 21.546875 C 22.0625 22.367188 18.214844 19.617188 17.746094 15.675781 C 17.296875 11.921875 19.84375 8.570312 23.578125 8 L 23.820312 7.953125 Z M 25.136719 7.929688 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="1718fc2cbf">
          <path
            d="M 15.304688 22.34375 L 35.800781 22.34375 L 35.800781 31.582031 L 15.304688 31.582031 Z M 15.304688 22.34375 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="492c773cce">
          <path
            d="M 22.160156 31.503906 L 18.929688 31.507812 C 18.761719 31.507812 18.675781 31.453125 18.59375 31.3125 L 15.484375 25.933594 C 15.398438 25.789062 15.394531 25.679688 15.480469 25.539062 C 16.425781 23.964844 17.820312 23.042969 19.617188 22.765625 C 21.320312 22.507812 22.789062 23.039062 24.039062 24.21875 L 24.257812 24.332031 C 25.351562 24.34375 26.328125 24.332031 27.304688 24.339844 C 28.070312 24.347656 28.582031 25.007812 28.390625 25.714844 C 28.257812 26.191406 27.835938 26.5 27.285156 26.5 L 23.214844 26.503906 C 22.597656 26.507812 22.199219 27.085938 22.429688 27.625 C 22.566406 27.945312 22.824219 28.125 23.171875 28.128906 L 27.246094 28.121094 C 27.847656 28.117188 28.386719 27.886719 28.878906 27.53125 L 33.742188 24.019531 C 34.152344 23.722656 34.597656 23.683594 35.039062 23.949219 C 35.46875 24.210938 35.632812 24.613281 35.566406 25.109375 C 35.523438 25.421875 35.355469 25.667969 35.105469 25.851562 L 29.03125 30.222656 C 27.769531 31.117188 26.347656 31.53125 24.792969 31.507812 L 22.160156 31.503906 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="4d0100d7ff">
          <path
            d="M 8 25.199219 L 17.65625 25.199219 L 17.65625 35.949219 L 8 35.949219 Z M 8 25.199219 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="2ac3684711">
          <path
            d="M 12.679688 25.222656 C 13.097656 25.238281 13.3125 25.417969 13.472656 25.695312 L 15.4375 29.101562 C 16.042969 30.152344 16.652344 31.199219 17.257812 32.25 C 17.570312 32.792969 17.46875 33.183594 16.929688 33.496094 L 13.363281 35.554688 C 12.8125 35.871094 12.429688 35.769531 12.113281 35.226562 L 8.347656 28.699219 C 8.039062 28.167969 8.140625 27.777344 8.667969 27.472656 L 12.28125 25.386719 C 12.429688 25.300781 12.59375 25.257812 12.675781 25.222656 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#1345ae8d1e)">
        <path
          fill="#ffffff"
          d="M 0.015625 0 L 45.988281 0 L 45.988281 43.003906 L 0.015625 43.003906 Z M 0.015625 0 "
          fillOpacity="1"
          fill-rule="nonzero"
        />
        <path
          fill={props.color ? props.color : '#ffffff'}
          d="M 0.015625 0 L 45.988281 0 L 45.988281 43.003906 L 0.015625 43.003906 Z M 0.015625 0 "
          fillOpacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clipPath="url(#43be0001db)">
        <g clipPath="url(#c0efcd0678)">
          <path
            fill="#2f0cf0"
            d="M 0.015625 0 L 43.695312 0 L 43.695312 43.003906 L 0.015625 43.003906 Z M 0.015625 0 "
            fillOpacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
      <g clipPath="url(#77f38e2ad1)">
        <g clipPath="url(#905a9b29b9)">
          <path
            fill="#2f0cf0"
            d="M 0.015625 0 L 43.695312 0 L 43.695312 43.003906 L 0.015625 43.003906 Z M 0.015625 0 "
            fillOpacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
      <g clipPath="url(#1718fc2cbf)">
        <g clipPath="url(#492c773cce)">
          <path
            fill="#2f0cf0"
            d="M 0.015625 0 L 43.695312 0 L 43.695312 43.003906 L 0.015625 43.003906 Z M 0.015625 0 "
            fillOpacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
      <g clipPath="url(#4d0100d7ff)">
        <g clipPath="url(#2ac3684711)">
          <path
            fill="#2f0cf0"
            d="M 0.015625 0 L 43.695312 0 L 43.695312 43.003906 L 0.015625 43.003906 Z M 0.015625 0 "
            fillOpacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
};

export default HandMoney;
